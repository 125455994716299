@import "flowbite";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Bebas+Neue&family=Tourney:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap");

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;

	/*  background-color: #f5f5f5;*/
	background-color: #ff8f8f;

	background-image: url("/public/img/bg/23532012.png");
	background-repeat: repeat;
	background-size: auto;
	background-position: top left;

	overflow: hidden;
}

body::after {
	background: repeating-linear-gradient(
		0deg,
		rgba(#1d1e22, 0.5),
		rgba(#1d1e22, 0.5) 1px,
		transparent 1px,
		transparent 2px
	);
}

@font-face {
	font-family: "pixellove";
	src: url("/public/fonts/pixellove.eot");
}

@font-face {
	font-family: "Cursive";
	src: url("/public/fonts/Cursive.eot");
}

@font-face {
	font-family: "monaco";
	src: url("https://pastelhell.neocities.org/fonts/monaco.eot");
	src:
		url("https://pastelhell.neocities.org/fonts/monaco.eot")
			format("embedded-opentype"),
		url("https://pastelhell.neocities.org/fonts/monaco.woff2") format("woff2"),
		url("https://pastelhell.neocities.org/fonts/monaco.woff") format("woff"),
		url("https://pastelhell.neocities.org/fonts/monaco.ttf") format("truetype"),
		url("https://pastelhell.neocities.org/fonts/monaco.svg#monaco")
			format("svg");
}

@font-face {
	font-family: "pixelscript";
	src: url("/public/fonts/PixelScriptRegular.ttf");
}

.about-me-container {
	font-family: "Pixelify Sans", sans-serif;
}

.pixelated-cursive-header {
	font-family: "pixelscript", sans-serif;
	font-style: normal;
	font-size: 36px;
	text-transform: uppercase;
	letter-spacing: 2px;
	word-spacing: 4px;
	margin-bottom: 20px;
}

.highlight-blue {
	color: #3a97ff;
	font-weight: bold;
}

.about-me-text {
	font-size: 18px;
	line-height: 1.5;
	text-align: justify;
	position: relative;
}

.avatar-left {
	float: left;
	width: 150px;
	height: auto;
	margin: 0 20px 20px 0;
}

.inline-image img {
	image-rendering: pixelated;
	margin: 0 5px;
	vertical-align: middle;
}

.stamps-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1px;
	flex-wrap: wrap;
}

.stamp-image {
	height: 60px;
	width: auto;
	image-rendering: pixelated;
}

.app-container {
	display: flex;
	height: 100vh;
}

.sidebar {
	width: 250px;
	background-color: #d8c3a5;
	color: #333;
	border-right: 2px solid #333;
	height: 100%;
	padding: 1rem;
}

.main-content {
	font-family: "Times New Roman", serif;
	padding: 1rem;
	background-color: #f0ebe0;
	flex-grow: 1;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.content {
	margin-left: 250px;
}

.navbar {
	background-color: #b9a76d;
	color: #333;
	font-family: "Courier New", Courier, monospace;
	border-bottom: 2px solid #333;
}

.vintage-frame {
	border: 5px solid #333;
	padding: 1rem;
	margin: 1rem;
}

.topsite-banner {
	width: 88px;
	height: 31px;
	border: 2px solid #333;
	text-align: center;
	font-size: 12px;
	color: #fff;
	background-color: #7a503d;
}

/* ===== Scrollbar CSS ===== */
/* https://codepen.io/stephenpaton-tech/full/JjRvGmY */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: #d3368a #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 16px;
}

*::-webkit-scrollbar-track {
	background: #ffffff;
}

*::-webkit-scrollbar-thumb {
	background-color: #d3368a;
	border-radius: 20px;
	border: 3px dashed #000000;
}

.wrapper {
	width: 700px;
	margin: 0 auto;
	margin-top: 60px;
}
.header {
	float: left;
	width: 700px;
	background-color: transparent;
	margin-top: 150px;
}
.wrapright {
	float: left;
	width: 700px;
	background-color: #ecfffc;
	margin-top: 20px;
}
.right {
	margin-left: 130px;
	background-color: #ffff;
	height: 690px;
}
.left {
	float: left;
	width: 120px;
	margin-left: -100%;
	margin-top: 20px;
}

body,
div,
a {
	color: #3b4269;

	margin-bottom: 1px;
	line-height: 12px;
	text-align: left;

	/*
	font: 9pt monospace;
	font-family: "Orbitron", monospace;
	*/
}
b {
	color: #fff93a;
	background-color: #ffdde5;
	/*
	font: 12px;
	font-family: "Orbitron", monospace;
	font-weight: bold;
	*/
}

u {
	color: #3a97ff;
	/*
	font: 12px Orbitron;
	font-weight: bold;
	*/
	/*
	text-decoration-style: dashed;
	*/
}

h1 {
	color: #79e3ff;
	font: 28px Bebas Neue;
	padding-left: -15px;
	margin-bottom: -1px;
	margin-top: -2px;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
}

h2 {
	color: #ff6fa6;
	background-color: #fffdd4;
	font: 10px;
	font-weight: bold;
	font-family: "Orbitron", monospace;
	padding-left: 0px;
	text-align: center;
	margin-bottom: 2px;
	margin-top: 1px;
	letter-spacing: 5px;
	text-transform: uppercase;
}

a:link,
a:visited,
a:active {
	color: #ff7d8b;
	font-size: 12px;

	font-family: "Orbitron", monospace;
	text-decoration: none;
	font-weight: bold;
	text-transform: uppercase;
}

table.linx {
	width: 108%;
	background-color: #ff7d8b;
	border-collapse: separate;
	color: #ffffff;
	text-align: center;
	text-decoration-style: none;
	padding: 5px;
}

a.x,
a.x:visited,
a.x:active {
	color: #fdff8f;
	font-family: Audiowide;
	font-weight: bold;
	font-size: 10pt;
	letter-spacing: -1px;
	text-decoration-style: none;
}
a.x:hover {
	color: #fbff00;
	text-decoration-style: none;
}

*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-track {
	background: #feffcc;
}

*::-webkit-scrollbar-thumb {
	background-color: #d7f2fe;
	border-radius: 0px;
	border: 8px none #ffffff;
}

.innerornate::-webkit-scrollbar-track {
	border-radius: 0px;
	background: transparent;
}

.innerornate::-webkit-scrollbar {
	width: 5px;
}

.innerornate::-webkit-scrollbar-thumb {
	background-color: #ffc821;
	border: 1px solid #5f2403;
}

.innerornate {
	width: 200px;
	height: 221px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	color: #102e1c;
	font-family: Verdana;
	font-size: 9px;
	letter-spacing: 1.5px;
	line-height: 12px;
	overflow-y: auto;
	overflow-x: hidden;
}

.ornatediv {
	width: 280px;
	height: 300px;
	background-image: url("https://i.ibb.co/TLW4K5h/freeframe.png");
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
}

ul li {
	list-style: square inside url("/public/img/bullets/h22.png");
	color: #b11751;
	font-weight: normal;
	margin-left: -5px;
	margin-top: 5px;
}

.hover-image-preview {
	position: absolute;
	left: 120%;
	top: 0;
	padding-left: 10px;
	display: block;
	background-color: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.hover-preview-img {
	width: 150px;
	height: auto;
}

/* https://codepen.io/tostrye/pen/WqKgjz */
a {
	font-weight: 400;
	color: #79b246;
	padding-bottom: 5px;
	background-image: url("/public/img/lines/pink-squiggly-underline.png");
	background-repeat: repeat-x;
	background-position: 0 100%; /* Start the squiggly at the bottom */
	background-size: 100% 10px; /* Adjust the size of the underline */
	text-decoration: none !important;
	transition: background-position 0.5s ease-in-out;
}

a:hover {
	background-position: 100% 100%; /* Move the squiggly to the right on hover */
}

@keyframes squiggly-move {
	0% {
		background-position: 0 100%; /* Start the squiggly at the left */
	}
	100% {
		background-position: 100% 100%; /* Move the squiggly to the right */
	}
}

.bg-pastel-peach {
	background-color: #ffccba;
}

.border-pastel-peach-dark {
	border-color: #fbb199;
}

.bg-pastel-pink {
	background-color: #ff99ae;
}

.bg-pastel-blue {
	background-color: #8df5fc;
}

.bg-pastel-green {
	background-color: #d4f400;
}

.pixel-font {
	font-family: "Press Start 2P", sans-serif;
	font-size: 14px;
	letter-spacing: 0.5px;
}

.hover:shadow-inner {
	box-shadow: inset 1px 1px 0 #000;
}

button {
	border: none;
	padding: 4px;
	font-size: 12px;
	font-family: "Press Start 2P", sans-serif;
	transition: transform 0.1s ease-in-out;
}

@media (max-width: 768px) {
	.image-shape {
		shape-outside: none;
		float: none;
		display: block;
		margin: 0 auto 20px auto;
	}
}
