$neon-color: #3a97ff;

.marquee {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 2.5rem;
	display: flex;
	align-items: center;
	background: black;

	&-content {
		display: inline-block;
		white-space: nowrap;
		font-size: 2rem;
		font-weight: bold;
		color: $neon-color;
		text-transform: uppercase;
		animation: marquee 10s linear infinite;

		text-shadow: 0 0 10px $neon-color, 0 0 20px $neon-color, 0 0 30px $neon-color;

		position: relative;
		&::after {
			content: '';
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 100%;
			height: 3px;
			background: linear-gradient(to top, $neon-color, transparent);
			box-shadow: 0 0 10px $neon-color, 0 0 20px $neon-color;
		}
	}
}

@keyframes marquee {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.marquee-container {
	width: 100%;
	white-space: nowrap;
	position: relative;
	overflow: hidden;
}

.marquee-content {
	display: flex;
	gap: 4rem;
	white-space: nowrap;
}

.animate-marquee {
	animation: marquee 10s linear infinite;
}

@keyframes marquee {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(-100%);
	}
}
